import React from 'react'
import {NavLink} from 'react-router-dom'

const BookingButton = ({text, url}) => {
  return (
    <>
        <NavLink to={url} >
        <button className="border border-black rounded-sm bg-yellow-500 text-gray-800 hover:bg-gray-800 hover:text-white ease-in-out duration-300 text-sm uppercase">
        <h5 className="px-12 py-4">{text}</h5>
        </button>
        </NavLink>
    </>
  )
}

export default BookingButton