import React from 'react'
import Footer from '../components/Footer'
import {NavLink} from 'react-router-dom'

const FAQ = () => {
  return (
    <main className="sm:text-2xl font-light tracking-wider leading-9 text-gray-500">
    <section className="sm:py-12 sm:text-2xl sm:px-96 m-12">
    <h4 className="sm:px-24 sm:py-24 sm:text-center sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">HOW DO I BOOK?</h4>
<p className="py-6 ">Your booking options are as follows: 

Head to the <NavLink to="/vallay-sands-booking" className="underline hover:text-gray-300 ease-in-out duration-300">booking page</NavLink> and use the calendar to book with our booking system provided by Free to Book. You can also <NavLink to="/vallay-sands-contact" className="underline hover:text-gray-300 ease-in-out duration-300">email or phone us direct</NavLink>, or book via <NavLink to="https://www.airbnb.co.uk/rooms/36365050?guests=1&adults=1&s=67&unique_share_id=73608b10-a630-436d-9b50-2101a8aa860e" className="underline hover:text-gray-300 ease-in-out duration-300">booking page</NavLink> and use the calendar to book with our booking system provided by Free to Book. You can also <NavLink to="/vallay-sands-contact" className="underline hover:text-gray-300 ease-in-out duration-300">Air BnB</NavLink> </p>

 

<h4 className="sm:px-24 sm:py-24 sm:text-center sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">HOW DO I FIND YOU?</h4>
<p className="py-6 ">Vallay Sands is in the township of Malacleit which is just to the west of the village of Sollas on North Uist. Heading east from the west side of the island you turn left, off at the corner just before you enter Sollas. Heading west out of Sollas turn right just as the road bends left at the end of a long straight. Vallay Sands is a few hundred meters along the track on the right. We’re not currently visible on Streetview because it’s a new house. We’re working on fixing that!
</p>
 

<h4 className="sm:px-24 sm:py-24 sm:text-center sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">CAN I BRING A PET?</h4>
<p className="py-6 ">We can accommodate one dog. We’ve got a dog of our own. There’s a garden around the house that visiting pets can use. Farming is a big thing here and there are many grazing animals around, many roaming free on the roads and verges so dogs need to be kept under control. 
</p>

<h4 className="sm:px-24 sm:py-24 sm:text-center sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">I’M COELIAC, CAN I EAT ANYTHING ON NORTH UIST?</h4>
<p className="py-6 ">In our experience the Westford Inn has the best menu for coeliacs. The chef is friendly and they take their time to check everything. Hamersay House in Lochmaddy is also supposed to be accommodating. 
</p>

<h4 className="sm:px-24 sm:py-24 sm:text-center sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">CAN I BRING CHILDREN?</h4>
<p className="py-6 ">We only have one bed in the room at the moment. We’re looking to expand the room to accommodate children in the future, but currently don’t have the space. Babies are welcome if you bring your own cot. Please give us advance notice so that we can prepare properly.
</p>

<h4 className="sm:px-24 sm:py-24 sm:text-center sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">IS NORTH UIST DANGEROUS?</h4>
<p className="py-6 ">Not particularly, but it can be a pretty wild place depending on the weather. Exercise caution if you’re crossing the sand to the island of Vallay, the tides can be very strong.  If you want to go for a swim, ask us for advice about the safe places to go.
</p> 




    </section>
    <Footer/>
</main>
  )
}

export default FAQ