import React from 'react'
import ActionButton from '../components/ActionButton'
import Footer from '../components/Footer'
import {NavLink} from 'react-router-dom'
import Machair from '../images/vallay-sands-drone-photo-machair-1.jpg'
import Udal from '../images/Udal_Settlement.jpg'
import Beach from '../images/Sollas_Beach.jpg'
import UdalView from '../images/Udal_Towards_Berneray.jpg'

import House from '../images/Vallay-Sands-bnb.jpg'
import Shells from '../images/Tiny_Shells.png'
import Sand from '../images/Rippled_Sand_Grenitote.jpg'
import Sunset1 from '../images/Sollas_Sunset.jpg'
import Sunset2 from '../images/Vallay_Sands_Sunset.jpg'
import Sunset3 from '../images/Vallay_Sands_Sunset_Orange.jpg'
import Cottage from '../images/Thatched_Cottage.jpg'
import Flowers from '../images/Machair_Vallay_Sands.png'


const About = () => {
  return (
    <main className="sm:text-2xl font-light tracking-wider leading-9 text-gray-500">
      <section>
        <img src={Machair} alt="A wide angle, ariel view of Vallay Sands at Malacleit in North Uist; green machair with remnants of stone walls and clear blue sea" className="w-full m-auto"/>
      </section>

      <section>
        
        <p className="sm:px-56 py-24 text-center sm:text-4xl font-light leading-10 tracking-tight leading-loose text-gray-900">
          WHERE THE EAGLE SOARS, THE CORNCRAKE CALLS AND THE RHYTHM OF NATURE IS STILL AT THE HEART OF DAILY LIFE.
          </p>
      </section>

      <section >
        <div className="py-12 sm:text-2xl sm:px-80 px-12">
          <p className="py-6">We look forward to welcoming you to North Uist.</p>

          <p className="py-6">We offer a warm introduction to the island and can offer advice on how to make the best use of time here.</p>

          <p className="py-6">Neil grew up in Uist and can tell you chapter and verse about this most special of places. Marion is from Germany; handy for some of the many European visitors who love to visit the Outer Hebrides.</p>

          <p className="py-6">Neil and Marion run <NavLink to="https://www.facebook.com/Hebrideanthatchingservices/" className="underline hover:text-gray-300 ease-in-out duration-300">Hebridean Thatching and Renovations</NavLink>, a business specialising in traditional building techniques. You can see their work dotted around the island and throughout the Scottish mainland.</p>
        </div>
      </section>

      <section className="flex justify-center">
        <ActionButton text="Availability & Booking" target="/vallay-sands-booking"/>
      </section>

      <section className="sm:flex px-12 pt-24">
       
        <div className="sm:px-12">
          <div className="pb-12">
          <img src={Udal} alt="Remnants of a dry stone wheel house structure at Udal on North Uist, surrounded by maram grass" />
          <small className="sm:text-sm text-xs sm:pl-0">Udal</small>
          </div>

          <h2 className="text-left text-3xl font-semibold leading-10 text-gray-900 tracking-tight uppercase">Local Area</h2>
          <p className="py-6 sm:pr-12">Long walks along the endless succession of immaculate beaches can be the best way of adjusting ‘Hebridean time’. Vallay Sands B&B lies near to the island of Vallay and a beach to two away from Udal, an old viking settlement. </p>

          <p className="py-6 sm:pr-12">Walking in the hills to the south of Sollas gives superb views of the island as well as the mysterious archipelago of St Kilda, some 40 miles to the north-west.</p>

          <p className="py-6 sm:pr-12">A great place for viewing St Kilda is nearby Scolpaig, designated as a National Scenic Area where the Atlantic meets some of the oldest rocks in the UK.</p>
        </div>
        <div>
          <div className="pb-12">
          <img src={Beach} alt="low tide at the beach near Sollas, wet sand with small shells and stones"  />
          <small className="sm:text-sm text-xs sm:pl-0">Beach near Vallay Sands in winter</small>
          </div>
          <h2 className="text-left text-3xl font-semibold leading-10 text-gray-900 tracking-tight uppercase">Local Wildlife</h2>
          <p className="py-6 sm:pr-12">North Uist is renowned for its wild life. From the house you can see majestic eagles, busy oyster catchers and lapwings, sanderlings, otters and red deer. In summer you’ll certainly hear and may even see the elusive corncrake!</p>

          <p className="py-6 sm:pr-12">Fishing and birdwatching are ever popular pastimes here with the Balranald RSPB reserve just 15 minutes drive to the west.</p>
        </div>
    
      </section>

      <section>
         
         <div className="grid grid-cols-1 sm:grid-cols-3 gap-12 sm:grid-rows-2 sm:p-12 px-12 sm:px-24 pb-12 sm:pb-0">
          
          <div className="sm:col-span-2 sm:row-span-2">
            <img src={House} alt="Vallay Sands bnb from above showing the house and surrounding garden" className="sm:h-full sm:w-auto w-full h-auto"/>
          </div>

          <div className="row-span-1">
            <img src={Shells} alt="Close up view of tiny shells on the beach near Malacleit"  className="sm:h-full sm:w-auto w-full h-auto"/>
          </div>
          <div className="row-span-1">
            <img src={Flowers} alt="Yellow wild flowers on the machair with the island of Vallay in the background"  className="sm:h-full sm:w-auto w-full h-auto" />
          </div>
         </div>


         







         <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-12 sm:grid-rows-2 sm:p-12 px-12 sm:px-24">
         
         <div className="col-span-1 sm:row-span-2 h-full mb-12 sm:mb-0">
            <div className="sm:h-1/2">
              <img src={Sunset1} alt="Sunset over the beach at Sollas on North Uist with patterns in the water and small clouds in the sky"  className="sm:h-full sm:w-full w-full h-auto pb-6 "/>
            </div>
            <div className="sm:h-1/2">
              <img src={Sunset2} alt="Sunset over North Uist with intense orange glow"  className="sm:h-full sm:w-auto w-full h-auto pt-6"/>
            </div>
         </div>
       
          <div className="col-span-2 row-span-2 bg-yellow-500 ">
            <img src={Sand} alt="Rippled patterns in the sand on the beach near Malacleit, North Uist" className="sm:h-full sm:w-full w-full h-auto" />
          </div>
       
         </div>


         <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 p-12 sm:px-24">
          <img src={Cottage} alt="Thatched cottage by the shore in Malacleit North Uist with Vallay Strand in the background"  className="sm:h-full sm:w-auto w-full h-auto" />
          <img src={Sunset3} alt="Orange sunset with the island of Vallay in the background"  className="sm:h-full sm:w-auto w-full h-auto" />
         </div>

      </section>

      <section className="flex sm:px-12 sm:pt-24"> 
        <div className="sm:pt-6 text-2xl sm:px-96 text-center">
        <h4 className="px-24 sm:py-24 text-center text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">Local Area</h4>
        <p className="py-6 pb-24 px-4 sm:px-0">Vallay Sands B&B lies on the stunning north-west coast of the island of North Uist. Here the wild flowers of the machair meet aquamarine rollers breaking on the beach just a few steps away.</p>
        </div>
      </section>

      <section className="flex justify-center">
        <ActionButton text="Availability & Booking" target="/vallay-sands-booking"/>
      </section>

      <section className="pt-24">
        <img src={UdalView} alt="View of North Uist from Udal with maram covered dunes in the foreground and low hills of North Uist beyond" className="w-full m-auto"/>
      </section>

      <Footer/>
    </main>
  )
}

export default About