import React from 'react'
import ActionButton from '../components/ActionButton'
import Footer from '../components/Footer'
import {NavLink} from 'react-router-dom'
import Machair from '../images/vallay-sands-drone-photo-machair-2.jpg'
import useScript from '../hooks/useScript'

const Embed = props => {
  useScript('https://widget.freetobook.com/widget.js?v=20190925')
  return (
    <div className="w-full flex justify-center ">
  <div className="sm:w-1/4 flex justify-center shadow-none rounded-none" class="ftb-widget" data-id="45394" data-style="review" data-token="hjmqo0JReO2KTuWSZsS4OA2myUjqE2gY0x1FpHj1osMgoEWd6adjdPSHMIp6Q"></div>    
  </div>       
  )                 
}

const Home = () => {
  return (
    <main className="sm:text-2xl font-light tracking-wider leading-9 text-gray-500">
      <section>
        <img src={Machair} alt="" className="m-auto"/>
      </section>

      <section >
        <div className="py-12 text-2xl sm:px-80">
          <p className="sm:px-36 text-center sm:text-3xl font-semibold leading-10 text-gray-900">NORTH UIST, THE MOST MAGICAL OF 
          <br></br>
          <br></br>
          ISLANDS IN SCOTLAND’S OUTER HEBRIDES.</p>
        </div>
        <div className="sm:px-80 m-12">
        <p className="py-6 ">
        Vallay Sands B&B lies on the stunning north-west coast of the island of North Uist. Here the wild flowers of the machair meet the crystal clear aquamarine sea breaking on the beach just a few steps away.
        </p>
        <p className="py-6">
        Your hosts Neil and Marion look forward to welcoming you in their eco-home, and making sure you get the most from your visit.
        </p>
        <p className="py-6">
        They offer a warm, welcoming <NavLink to="/accommodation" className="underline hover:text-gray-300 ease-in-out duration-300">room</NavLink> where you can unwind surrounded by unspoilt nature, and adjust to Hebridean time.  
        </p>
        <p className="py-6">
        <NavLink to="/about" className="underline hover:text-gray-300 ease-in-out duration-300">North Uist</NavLink> is renowned for its wild life. From the house you can see majestic eagles, busy oyster catchers and lapwings, sanderlings. Otters and red deer also make an appearance. During the summer you’ll certainly hear, and may even see the elusive corncrake!
        </p>
        </div>
      </section>
      <section className="flex justify-center">
        <ActionButton text="Availability & Booking" target="/vallay-sands-booking"/>
      </section>
      <section className="pt-8">
       <Embed />
        {/* <NavLink to="https://www.freetobook.com/reviews/all?w_id=45394&w_tkn=hjmqo0JReO2KTuWSZsS4OA2myUjqE2gY0x1FpHj1osMgoEWd6adjdPSHMIp6Q">See all reviews</NavLink> */}
      </section>
      <Footer />
    </main>
  )
}

export default Home