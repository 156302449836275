import React from 'react'
import Footer from '../components/Footer'
import MailTo from '../components/MailTo'

const Contact = () => {
  return (
    <main className="sm:text-2xl font-light tracking-wider leading-9 text-gray-500">
        <section className="sm:py-12 sm:text-2xl sm:px-64">
        <div className="sm:py-12 text-2xl sm:px-64">
        <h2 className="text-left sm:text-3xl font-semibold leading-10 text-gray-900">Contact</h2>
         

         
        </div>
       
        <div className="sm:px-64">
            <p>Feel free to get in touch if you have any questions or want to find out more. </p>
            <ul className="py-12">
                <li>Email: <MailTo label="uistcottages@aol.com" mailto="mailto:uistcottages@aol.com" /></li>
                <li>Phone: 07769 722699</li>
                <li>Address: 5 Malaclate, Sollas, Isle of North Uist HS6 5BX</li>
            </ul>

           
        </div>

        </section>
        
        <section className="sm:py-12 sm:text-2xl sm:px-64">
            {/* map */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2135.047307697404!2d-7.371231484333497!3d57.64768275173318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x488d17bd9034afe5%3A0x4e12412452820a6!2sVallay%20sands!5e0!3m2!1sen!2suk!4v1579110021939!5m2!1sen!2suk" width="100%" height="100%" frameborder="0" style={{ border:0}} allowfullscreen="" title="map of vallay sands"></iframe>


            <div className="sm:px-64 pt-12">
                <h4>HOW TO FIND US</h4>
                <p>Turn off the A865 onto a minor road branching northwards at Malaclate.  This turnoff is about 1 mile west of Sollas Coop. 

                </p>
            </div>
        </section>
        <Footer />
    </main>
  )
}

export default Contact