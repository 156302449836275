import React from 'react'
import {NavLink} from 'react-router-dom'

const Footer = () => {
  return (
     <footer className="m-auto text-center pb-16 pt-12">
        <div className="p-4">
            <h4 className="text-3xl font-semibold leading-10 text-gray-900">Vallay Sands B&B</h4>
        </div>
        <div>
            <nav className="flex justify-center text-gray-500">
                <NavLink to="/vallay-sands-contact">
                    <h6 className="p-4 hover:text-gray-300 ease-in-out duration-300 text-sm uppercase">CONTACT</h6>
                </NavLink>
                <NavLink to="/vallay-sands-faq">
                    <h6 className="p-4 hover:text-gray-300 ease-in-out duration-300 text-sm uppercase">FAQ</h6>
                </NavLink>
                <NavLink to="/vallay-sands-privacy-policy">
                    <h6 className="p-4 hover:text-gray-300 ease-in-out duration-300 text-sm uppercase">PRIVACY POLICY</h6>
                </NavLink>
            </nav>
        </div>
     </footer>
   
  )
}

export default Footer