import React, {useState, useEffect} from 'react'
// import Bathroom from '../images/Vallay_Sands_Bathroom.jpg'
// import Bed from '../images/Vallay_Sands_Bed_Interior.jpg'
// import Bedroom from '../images/Vallay_Sands_Bedroom_Interior.jpg'
// import Owl from '../images/Vallay_Sands_Interior_Owl.jpg'
import UpstairsBedroom from '../images/Vallay-Sands-Upstairs-Bedroom.jpg'
import UpstairsWindow from '../images/Vallay-Sands-Upstairs-Window.jpg'
import UpstairsBathroom from '../images/Vallay-Sands-Upstairs-Bathroom.jpg'

// import BedroomDoor from '../images/Vallay_Sands_BnB_Bedroom_Door.jpg'
import Bedroom0 from '../images/Vallay_Sands_BnB__Bedroom_0.jpg'
import Bedroom1 from '../images/Vallay_Sands_BnB_Bedroom_1.jpg'
import Bedroom2 from '../images/Vallay_Sands_BnB__Bedroom_2.jpg'
import Bedroom3 from '../images/Vallay_Sands_BnB__Bedroom_3.jpg'
import Bedroom4 from '../images/Vallay_Sands_BnB__Bedroom_4.jpg'
import Bedroom5 from '../images/Vallay_Sands_BnB__Bedroom_5.jpg'
import Bedroom6 from '../images/Vallay_Sands_BnB__Bedroom_6.jpg'
import Bedroom7 from '../images/Vallay_Sands_BnB__Bedroom_7.jpg'
import Bedroom8 from '../images/Vallay_Sands_BnB__Bedroom_8.jpg'
import Bedroom9 from '../images/Vallay_Sands_BnB__Bedroom_9.jpg'


import {IoIosArrowBack} from 'react-icons/io'
import {IoIosArrowForward} from 'react-icons/io'

const Carousel = () => {

  const [counter, setCounter] = useState(0)

  const increment = () => {
    const plusValue = counter + 1
    if(plusValue < images.length){
        setCounter(plusValue)   
    }else{
        setCounter(0)
    }   
}

const decrement = () => {
    const minusValue = counter -1
    if(minusValue > 0){
    setCounter(minusValue)
    }else{
    setCounter(images.length -1)
    }
}

const [seconds, setSeconds] = useState(3)

const handleTimer = () => {
    let interval = setInterval(()=> {
        if(seconds > 0){
            setSeconds(seconds -1)
        }
        
        
        clearInterval(interval)
        increment()
        
    },4000)
}

useEffect(()=>{
  handleTimer()
})

  const images = [Bedroom0, Bedroom1, Bedroom2, Bedroom3, Bedroom4, Bedroom5, Bedroom6, Bedroom7, Bedroom8, Bedroom9, UpstairsBedroom, UpstairsWindow, UpstairsBathroom]

  console.log(counter)
  return (
    <div className="w-full sm:h-screen h-64 bg-cover bg-center ease-in-out duration-300" style={{backgroundImage: `url(${images[counter]})` }} role="img"
    aria-label="Images showing the bright interior of Vallay Sands BnB with four poster bed and large windows"
    title="Tooltip for users not using assistive technologies">
      {/* <img src={images[counter]} alt="" /> */}
      <div className="flex justify-between w-full h-full items-center ">
      <IoIosArrowBack id="" label="Back" 
                        style={{height: '4em', width: '3em', color: "white", backgroundColor: "rgba(0, 0, 0, 0.2)", cursor: "pointer" }}
                        onClick={decrement} className="arrow" aria-label="Previous image"/>
                        
                <IoIosArrowForward id="" label="Forward" 
                        style={{height: '4em', width: '3em', color: "white", backgroundColor: "rgba(0, 0, 0, 0.2)", cursor: "pointer"}}
                        onClick={increment} className="arrow" aria-label="Next image"/>
      </div>

        <div className="flex pt-4 justify-center invisible sm:visible">
          {images && images.map((image, index)=> (
            <div key={index} className="h-24">
              <img src={image} alt="Small scale display of photos of the inside of vallay sands bnb" className={`h-12 w-auto  ${index === counter ? `opacity-50` : ``}`}/>
            </div>
          ))}
        </div>
    
    </div>
    
  )
}

export default Carousel