import React from 'react'
import {NavLink} from 'react-router-dom'

const Header = () => {
  return (
    <div className="p-12 m-auto text-center">
      <h1 className="sm:text-5xl text-3xl pb-2 sm:pb-0">Vallay Sands B&B</h1>
        <nav className="flex justify-center hover:text-gray-400 flex-wrap sm:flex-nowrap">
            <NavLink to="/"><h4 className="sm:p-4 px-2 hover:text-gray-900 ease-in-out duration-300">Home</h4></NavLink>
            <NavLink to="/vallay-sands-about"><h4 className="sm:p-4 px-2 hover:text-gray-900 ease-in-out duration-300">About</h4></NavLink>
            <NavLink to="/vallay-sands-accommodation"><h4 className="sm:p-4 px-2 hover:text-gray-900 ease-in-out duration-300">Accommodation</h4></NavLink>
            <NavLink to="/vallay-sands-things-to-do"><h4 className="sm:p-4 px-2 hover:text-gray-900 ease-in-out duration-300">Things To Do</h4></NavLink>
            <NavLink to="/vallay-sands-booking"><h4 className="sm:p-4 px-2 hover:text-gray-900 ease-in-out duration-300">Booking</h4></NavLink>
        </nav>
    </div>
  )
}

export default Header