import React from 'react'
import ActionButton from '../components/ActionButton'
import Footer from '../components/Footer'
import Video from '../components/Video'
import {NavLink} from 'react-router-dom'
import Ploughing from '../images/Ploughing_Competition_Sollas_2019.png'

const ThingsToDo = () => {
  return (
    <main className="sm:text-2xl font-light tracking-wider leading-9 text-gray-500">

      <section className="sm:flex">
        <div className="h-52 sm:h-full w-auto sm:m-auto sm:px-12">
        <img src={Ploughing} alt="Ploughing competition on the machair at Sollas 2019 - furrows visible with people coming and going plus tractors" className="object-cover w-full h-48 sm:h-full "/>
        <small className="sm:text-sm text-xs pl-2 sm:pl-0 ">Ploughing competition on the machair at Sollas 2019</small>
        </div>
      </section>

      <section className="justify-center sm:px-80 m-12">

        <div className="py-12 sm:text-2xl sm:px-12">
          <p className="text-left text-3xl font-semibold leading-10 text-gray-900">
            THINGS TO DO
          </p>
        </div>

          <div className="sm:py-12 sm:text-2xl sm:px-12">
          <p>A not at all exhaustive list of some of the things on offer around North Uist and its close neighbouring islands.   </p>
          </div>
          {/* VIDEO EMBED */}
          <Video src="https://player.vimeo.com/video/386461016?h=76318fc66f" />
      </section>

     

      <section className="justify-center sm:px-80 ">
        <div className="sm:py-12 sm:text-2xl sm:px-12">
          <h4 className="font-semibold pt-6">Arts and Crafts</h4>

          <p className="py-6 ">The island of Grimsay has a strong community of small businesses and  resources. <NavLink to="https://www.uistwool.com/" className="underline hover:text-gray-300 ease-in-out duration-300">Uist Wool</NavLink> is an excellent place to visit if you’re into knitting. 
          </p>
          <p className="py-6 ">
          Situated just outside the village of Clachan, the <NavLink to="http://www.hebrideansmokehouse.com/" className="underline hover:text-gray-300 ease-in-out duration-300">Hebridean Smokehouse</NavLink>  sells a wide variety of locally caught and smoked fish.
          </p>
          <p className="py-6 ">
          Just next to the ferry terminal in Lochmaddy is <NavLink to="https://www.taigh-chearsabhagh.org/" className="underline hover:text-gray-300 ease-in-out duration-300">Taigh Chearsabhagh</NavLink> Taigh Chearsabhagh museum and art centre. It has a cafe and a range of exhibitions featuring local and international artists. 
          </p>
          <p className="py-6 ">
          On the way out to Locheport you’ll find <NavLink to="https://shoreline-stoneware.co.uk/" className="underline hover:text-gray-300 ease-in-out duration-300">Shoreline Stoneware</NavLink>.
          </p>
          <p className="py-6 ">
          <NavLink to="http://www.claddach-kirkibost.org/" className="underline hover:text-gray-300 ease-in-out duration-300">Claddach Kirkibost Centre</NavLink> contains a Craft Hub, Cafe and Gym along with a charge point for electric vehicles and a soft-play area for children. 
          </p>
        </div>

        <div className="sm:py-12 sm:text-2xl sm:px-12">
         <h4 className="font-semibold pt-6">Walks Through Time</h4>

          <p className="py-6 ">
          <NavLink to="https://www.ramblers.org.uk/news/walk-magazine/current-issue/2015/august/autumn-2015/rrid-hebridean-way.aspx" className="underline hover:text-gray-300 ease-in-out duration-300">The Hebridean Way</NavLink> runs through the whole of the ‘long island’ and passes through North Uist just near Vallay Sands. 
          </p>
          <p className="py-6 ">
          The <NavLink to="https://www.walkhighlands.co.uk/outer-hebrides/north-uist.shtml" className="underline hover:text-gray-300 ease-in-out duration-300">Walk Highlands</NavLink> website has articles on many walks that take you to some stunning places around the islands. 
          </p>
          <p className="py-6 ">
          The <NavLink to="https://www.visitouterhebrides.co.uk/see-and-do/activities/langass-woodland-p532841" className="underline hover:text-gray-300 ease-in-out duration-300">Langass Community Woodland</NavLink> is a good focal point for exploring some of the archaeological treasures nearby with the ancient <NavLink to="https://en.wikipedia.org/wiki/Barpa_Langass">Barpa Langass</NavLink> stone cairn and <NavLink to="https://www.scottish-places.info/features/featurefirst90664.html">Pobull Fhìnn</NavLink> stone circle a short walk away. It also has a sculpture of <NavLink to="https://en.wikipedia.org/wiki/Hercules_(bear)">Hercules the bear</NavLink>, who is buried in the woods. In 1980 this celebrity bear escaped from a film shoot nearby and spent a few days on the run, enjoying North Uist’s moors. 
          </p>
          </div>
          <div className="sm:py-12 sm:text-2xl sm:px-12">
          <h4 className="font-semibold pt-6">Sporting Activities</h4>
          <p className="py-6 ">
            There’s a wide range of beaches and shores to choose from, both sheltered and exposed, making North Uist ideal for a variety of water sports. 

            Kite Surfing

            Wild Swimming

            Kayaking

            Windsurfing

            Sand Yachting

            Flying
            People have also been known to <NavLink to="https://www.youtube.com/watch?v=yo1U-am74aU" className="underline hover:text-gray-300 ease-in-out duration-300">land planes </NavLink>on the beach at <NavLink to="https://www.ukairfieldguide.net/airfields/Sollas" className="underline hover:text-gray-300 ease-in-out duration-300">Sollas</NavLink> 
          </p>

        </div>

<div className="sm:py-12 sm:text-2xl sm:px-12">
<h4 className="font-semibold pt-6">Eating & Drinking</h4>
  <p className="sm:py-6 py-2 ">
  <NavLink to="https://westfordinn.com/" className="underline hover:text-gray-300 ease-in-out duration-300">Westford Inn</NavLink>
  </p>
  <p className="sm:py-6 py-2 ">
  <NavLink to="https://www.facebook.com/theweecottagekitchen/" className="underline hover:text-gray-300 ease-in-out duration-300">The Wee Cottage Kitchen</NavLink>
  </p>
  <p className="sm:py-6 py-2 ">
  <NavLink to="http://www.bernerayshopandbistro.co.uk/" className="underline hover:text-gray-300 ease-in-out duration-300">Berneray Bistro</NavLink>
  </p>
  <p className="sm:py-6 py-2 ">
  <NavLink to="https://www.google.com/search?npsic=0&q=kallin%20harbour%20cafe&rflfq=1&rldoc=1&rlha=0&rllag=57479564%2C-7204658%2C148&rlz=1C5CHFA_enGB850GB850&tbm=lcl&tbs=lrf%3A%211m5%211u8%212m3%218m2%211u32200%213e1%211m4%211u3%212m2%213m1%211e1%211m4%211u2%212m2%212m1%211e1%212m4%211e17%214m2%2117m1%211e2%212m1%211e2%212m1%211e3%213sIAE%2Clf%3A1%2Clf_ui%3A2&ved=2ahUKEwiIn7Srp5jnAhXQSxUIHZ4yAzgQtgN6BAgLEAQ#rlfi=hd:;si:8293381145731926446,l,ChNrYWxsaW4gaGFyYm91ciBjYWZlWiMKDGhhcmJvdXIgY2FmZSITa2FsbGluIGhhcmJvdXIgY2FmZQ;mv:[[57.48394939999999,-7.2037672],[57.4779329,-7.211531299999999]]" className="underline hover:text-gray-300 ease-in-out duration-300">Namara Marine Supplies & Seafood Cafe</NavLink>
  </p>
  <p className="sm:py-6 py-2 ">
  <NavLink to="https://www.grimsay.org/cafe" className="underline hover:text-gray-300 ease-in-out duration-300">The Grimsay Kitchen at Grimsay Community Association</NavLink>

</p>
</div>
      </section>

      <section className="flex justify-center">
        <ActionButton text="Availability & Booking" target="/vallay-sands-booking"/>
      </section>
      <Footer />
    </main>
  )
}

export default ThingsToDo