import { BrowserRouter, Route, Routes} from 'react-router-dom'
import Header from './components/Header'
import Home from './pages/Home'
import About from './pages/About'
import Accommodation from './pages/Accommodation'
import Booking from './pages/Booking'
import ThingsToDo from './pages/ThingsToDo'
import FAQ from './pages/FAQ'
import Contact from './pages/Contact'
import PrivacyPolicy from './pages/PrivacyPolicy'

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route element={<Home/>} path="/" exact ="true"/>
        <Route element={<About/>} path="/vallay-sands-about" />
        <Route element={<Accommodation/>} path="/vallay-sands-accommodation" />
        <Route element={<Booking/>} path="/vallay-sands-booking" />
        <Route element={<ThingsToDo/>} path="/vallay-sands-things-to-do" />

        <Route element={<FAQ/>} path="/vallay-sands-faq" />
        <Route element={<Contact/>} path="/vallay-sands-contact" />
        <Route element={<PrivacyPolicy/>} path="/vallay-sands-privacy-policy" />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
