import React from 'react'

const Video = ({src}) => {
  return (
    <iframe 
    width="853"
    height="480"
    src={src}
    title="vimeo-player"
    frameborder="0"
    allowFullScreen
    
    className="w-72 h-56  sm:w-full sm:h-screen"/>
  )
}

export default Video

