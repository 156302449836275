import React from 'react'
import {NavLink} from 'react-router-dom'


const ActionButton = ({text, target}) => {
  return (
    <NavLink to={target}>
    <button className="border border-black rounded-sm hover:bg-gray-800 hover:text-white ease-in-out duration-300 text-sm uppercase">
        <h5 className="px-12 py-4">{text}</h5>
    </button>
    </NavLink>
  )
}

export default ActionButton