import React from 'react'
import Carousel from '../components/Carousel'
import ActionButton from '../components/ActionButton'
import Footer from '../components/Footer'

import Bailey from '../images/Bailey.jpg'

const Accommodation = () => {
  return (
    <main className="sm:text-2xl font-light tracking-wider leading-9 text-gray-500">
      <section className="flex justify-center px-4 sm:px-24">
        <Carousel />
      </section>

      <section className="flex justify-center sm:px-80 m-12 sm:pt-24">
        <div className="py-12 sm:text-2xl px-12 sm:px-0">
          <h2 className="text-left text-3xl font-semibold leading-10 text-gray-900">ACCOMMODATION</h2>
          <p className="py-6" >We offer two standard double rooms; a ground floor Four Poster Room with private entrance, and a first floor Upstairs Room with shared access (with owners).     </p>
         
          <h4 className="font-semibold pt-6">Four Poster Room</h4>
          <p className="py-6 ">Your room, with private entrance, has views on one side over the beach to the beautiful tidal island of Vallay and on the other side to the island of Berneray and the hills of Harris.</p>

          <p className="py-6 ">The accommodation is in classic rustic-style crowned with a romantic four-poster bed.
          </p>
          <p className="py-6 ">The lounge area has sofas, a little dining area, a TV and Freesat (in a cabinet though, if you don’t want to be reminded of it!)  and free, fast wi-fi.
        </p>
        <p className="py-6 ">There’s an ensuite shower room with all the toiletries you might need, along with a hairdryer.
        </p>
        <p className="py-6 ">Heating is as green as it gets with gel radiators powered by our own wind turbines.
        </p>


        <h4 className="font-semibold pt-6">Upstairs Room</h4>

        <p className="py-6 ">Your first floor room has views over the beach to the beautiful island of Vallay and the hills of Harris.</p>

        <p className="py-6 ">There’s an ensuite shower room with all the toiletries you might need, along with a hairdryer.</p>


        <h4 className="font-semibold pt-6">Breakfast</h4>
          <p className="py-6 ">With the beach so close you may well find yourself heading out for a walk by the sea before breakfast.</p>

          <p className="py-6 ">Continental Breakfast will be delivered to your room in the morning.</p>
          


        <h4 className="font-semibold pt-6">Pets</h4>

        <p className="py-6 ">We’re happy to accommodate up to one dog provided you tell us in advance. We have our own well behaved dog who will be confined to our part of the house. </p>

        <p className="py-6 ">The house is surrounded by a garden that pets can use. Our dog will be using the same garden.</p>

          <div className="pb-6 flex justify-center flex-col">
          <img src={Bailey} alt="Our dog Bailey enjoying a walk on Malaclate beach, a brown smiling dog on a wide open beach with sea in the background" />
          <small className="text-sm">Our dog Bailey enjoying a walk on Malaclate beach</small>
          </div>

        </div>
      </section>
      <section className="flex justify-center">
        <ActionButton text="Availability & Booking" target="/vallay-sands-booking"/>
      </section>
      <Footer />

    </main>
  )
}

export default Accommodation