import React from 'react'
import Udal from '../images/Udal_Towards_Vallay.jpg'
import ActionButton from '../components/ActionButton'
import BookingButton from '../components/BookingButton'
import Footer from '../components/Footer'
import MailTo from '../components/MailTo'

const Booking = () => {
  return (
    <main className="sm:text-2xl font-light tracking-wider leading-9 text-gray-500">

        <section className="sm:flex">
        <div className="h-52 sm:h-full w-auto sm:m-auto sm:px-12">
        <img src={Udal} alt="Sollas in the distance viewed from Udal, North Uist" className="object-cover w-full h-48 sm:h-full "/>
        <small className="sm:text-sm text-xs pl-2 sm:pl-0 ">Sollas in the distance viewed from Udal, North Uist</small>
        </div>
      </section>

      <section>

        <div className="py-12 text-2xl sm:px-80 m-12">
          <p className="text-left sm:text-3xl font-semibold leading-10 text-gray-900">
            AVAILABILITY & BOOKING
          </p>

          <p className="py-12 sm:text-2xl">Click the button below to start the booking process with Free to Book (external site):</p>
          <div className="text-center">
          <BookingButton text="Booking" url="https://portal.freetobook.com/reservations?w_id=45394&w_tkn=hjmqo0JReO2KTuWSZsS4OA2myUjqE2gY0x1FpHj1osMgoEWd6adjdPSHMIp6Q"/>
          </div>
        </div>     

      </section>



      <section>
      <div className="sm:px-80 m-12">
      <h4 className="font-semibold pt-6">Contact us:</h4>

<p className="py-6 "> <MailTo label="Email uistcottages@aol.com" mailto="mailto:uistcottages@aol.com" /> or call 07769 722699 </p>

<p className="py-6 ">NOTE: The Ferries get busy over summer. If you’re coming by water, make sure to book the ferry as soon as you’ve confirmed your plans! </p>

<p className="py-6 ">If you have any other questions about your stay feel free to get in touch. </p>
        
      </div>
      </section>

      <section className="flex justify-center">
        <ActionButton text="Contact" target="/vallay-sands-contact"/>
      </section>
      <Footer />

    </main>
  )
}

export default Booking