import React from 'react'
import Footer from '../components/Footer'

const PrivacyPolicy = () => {
  return (
    <main className="sm:text-2xl font-light tracking-wider leading-9 text-gray-500">
        <section className="sm:py-12 sm:text-2xl sm:px-96 m-12">
            {/* <div>
        <h2>PRIVACY POLICY</h2>
        <p>Here you’ll find information on how we treat data that we collect from visitors to our website.</p>
        </div> */}

        <div className="sm:py-12 sm:text-2xl">
        <h2 className="text-left sm:text-3xl font-semibold leading-10 text-gray-900">PRIVACY POLICY</h2>
        <p className="py-6 sm:text-2xl">Here you’ll find information on how we treat data that we collect from visitors to our website.</p>


         
        </div>

        <div>
            <h4 className="sm:py-12 sm:text-left sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">Visitors to Our Website</h4>
            <p className="py-6 ">When someone visits www.uiststays.com we use a third party service, Google Analytics,  to collect standard internet log information and details of visitor behaviour patterns. We do this to find out things such as the number of visitors to the various parts of the site. This information is only processed in a way which does not identify anyone. We do not make any attempt to find out the identities of those visiting our website. You can find more information on how cookies are used on this website in the Cookies Policy below.
</p>
        </div>
        <div>
            <h4 className="sm:py-12 sm:text-left sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">Links to Other Websites</h4>
            <p className="py-6 ">This privacy notice does not cover the links within this site linking to other websites. Those sites are not governed by this Privacy Policy, and if you have questions about how a site uses your information, you’ll need to check that site’s privacy statement. 
</p>
        </div>
        <div>
            <h4 className="sm:py-12 sm:text-left sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">Access to Your Personal Information</h4>
            <p className="py-6 ">You are entitled to access the personal information that we hold. Email your request to uistcottages@aol.com
</p>
        </div>
        <div>
            <h4 className="sm:py-12 sm:text-left sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">Is Personal Information About Me Secure?</h4>
            <p className="py-6 ">We use appropriate  administrative security measures to protect any information we hold from loss, misuse, unauthorised access, disclosure, alteration and destruction.  Unfortunately, no company or service can guarantee complete security. Unauthorised entry or use, hardware or software failure, and other factors may compromise the security of user information at any time.
</p>
        </div>
        <div>
            <h4 className="sm:py-12 sm:text-left sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">Cookies Policy</h4>
            <p className="py-6 ">We use cookies on this website for various purposes detailed below.
</p>
        </div>
        <div>
            <h4 className="sm:py-12 sm:text-left sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">What are cookies?</h4>
            <p className="py-6 "> A cookie is a piece of information in the form of a small text file that is placed on an internet user’s computer or mobile phone when you first visit a website or page. Cookies are typically made up of letters and numbers. They are stored on your computer by your internet browser, which are then sent back to the originating website, which recognises the cookie. These cookies allow the website to distinguish you from other users of the website, which helps us to provide you with a good experience when you browse our website and allows us to improve our site.
</p>
        </div>
        <div>
            <h4 className="sm:py-12 sm:text-left sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">Browser’s settings</h4>
            <p className="py-6 ">Most web browsers allow some control of most cookies through the browser settings. To find out more about cookies visit <a href="http://www.aboutcookies.org.uk/">aboutcookies.org.uk.</a> aboutcookies.org.uk.
</p>
        </div>
        <div>
            <h4 className="sm:py-12 sm:text-left sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">Removing cookies</h4>
            <p className="py-6 ">You can withdraw your consent and remove or block cookies at any time using the settings in your browser, but in some cases this may impact your ability to use a website.
            </p>
        </div>
        <div>
            <h4 className="sm:py-12 sm:text-left sm:text-3xl font-semibold leading-10 tracking-tight leading-loose text-gray-900 uppercase">Why do we use cookies?</h4>
            <p className="py-6 ">Most websites use cookies to make your visit to the site easier and to improve the quality of your user experience: by enabling cookies, a user’s preferences, information and general statistics can be stored. These statistics are things like how many people visit our website, what type of browser/technology they are using, and how long they spend on our site. These cookies help us to continuously measure and improve the performance of the website, and ultimately the user experience.
        </p>
        </div>


 


        </section>

        <Footer/>
    </main>
  )
}

export default PrivacyPolicy